import * as React from "react"
import { Preview } from "./Preview/Preview"
import { Settings } from "./Settings/Settings"

export interface ICreateProps {}

export function Create(props: ICreateProps) {
  return (
    <div className="d-flex">
      <div className="left-container">
        <Settings />
      </div>
      <div className="right-container">
        <Preview />
      </div>
    </div>
  )
}
