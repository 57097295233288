import * as React from "react"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks"
import { dateDefined, textDefined, usernameDefined } from "../../../../store/stickerSettings/stickerSettingsSlice"

export interface ISettingsProps {}

export function Settings(props: ISettingsProps) {
  const { username, date, text } = useAppSelector(
    state => state.stickerSettings
  )
  const dispatch = useAppDispatch()
  return (
    <div className="d-flex flex-column align-items-center">
      <h2>Customize Your Sticker!</h2>
      <div className="mb-3">
        <label htmlFor="username" className="form-label">
          Username
        </label>
        <input
          defaultValue={username}
          type="text"
          className="form-control"
          id="username"
          onChange={event =>
            dispatch(usernameDefined({ username: event.target.value }))
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="date" className="form-label">
          Date
        </label>
        <input
          defaultValue={date}
          type="text"
          className="form-control"
          id="date"
          onChange={event =>
            dispatch(dateDefined({ date: event.target.value }))
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="text" className="form-label">
          Text
        </label>
        <input
          defaultValue={text}
          type="text"
          className="form-control"
          id="text"
          onChange={event =>
            dispatch(textDefined({ text: event.target.value }))
          }
        />
      </div>
    </div>
  )
}
