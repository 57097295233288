import { Link } from "gatsby"
import * as React from "react"

export interface IGenerateMockUpButtonProps {}

export function GenerateMockUpButton(props: IGenerateMockUpButtonProps) {
  return (
    <>
      <p className="mt-5">Done customizing? Generate your sticker mock ups!</p>
      <Link className="btn btn-primary" to="/mock-ups">
        Generate Mock-Ups!
      </Link>
    </>
  )
}
