import * as React from 'react';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import "../../../../styles/github.css"

export interface IStickerPreviewProps {
}

export function StickerPreview (props: IStickerPreviewProps) {

  const { username, date, text } = useAppSelector(
    state => state.stickerSettings
  )

  return (
    <div className="logged-in env-production page-responsive intent-mouse" style={{wordWrap: 'break-word'}}>
        <div data-color-mode="light" data-light-theme="light" data-dark-theme="dark">
          <div className="
            container-md
            clearfix
            new-discussion-timeline
            px-3 px-md-4 px-lg-5
            pt-1
          ">
            <div className="TimelineItem pt-0 js-comment-container">
              <div className="avatar-parent-child TimelineItem-avatar d-none d-md-block">
                <div className="d-inline-block">
                  <img className="avatar rounded-1 avatar-user" height={40} width={40} alt="FullStackCraft's avatar." src="https://coffee-app.sfo2.cdn.digitaloceanspaces.com/fullstackcraft/logo.jpg" />
                </div>
              </div>
              <div className="
                timeline-comment-group
                js-minimizable-comment-group js-targetable-element
                TimelineItem-body
                my-0
              ">
                <div className="
                  ml-n3
                  timeline-comment
                  unminimized-comment
                  comment
                  previewable-edit
                  js-task-list-container js-comment
                  timeline-comment--caret
                  reorderable-task-lists
                ">
                  <input type="hidden" defaultValue="ESO2IzbXY+Zp53P4F71CToYYQbQkHghsae65KuevIhd49jEN5uKLGMNJsP0w0FutnOrjpMNIc/T5GQatnjjMiw==" className="js-data-unfurl-hide-url-csrf" />
                  <div className="timeline-comment-header clearfix d-block d-sm-flex">
                    <div className="timeline-comment-actions flex-shrink-0">
                      <details className="
                        details-overlay details-reset
                        position-relative
                        js-reaction-popover-container
                        js-comment-header-reaction-button
                        d-none d-md-inline-block
                      ">
                        <summary className="btn-link Link--secondary timeline-comment-action" role="button">
                          <svg viewBox="0 0 16 16" version="1.1" height={16} width={16} className="octicon octicon-smiley">
                            <path fillRule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zM5 8a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zM5.32 9.636a.75.75 0 011.038.175l.007.009c.103.118.22.222.35.31.264.178.683.37 1.285.37.602 0 1.02-.192 1.285-.371.13-.088.247-.192.35-.31l.007-.008a.75.75 0 111.222.87l-.614-.431c.614.43.614.431.613.431v.001l-.001.002-.002.003-.005.007-.014.019a1.984 1.984 0 01-.184.213c-.16.166-.338.316-.53.445-.63.418-1.37.638-2.127.629-.946 0-1.652-.308-2.126-.63a3.32 3.32 0 01-.715-.657l-.014-.02-.005-.006-.002-.003v-.002h-.001l.613-.432-.614.43a.75.75 0 01.183-1.044h.001z" />
                          </svg>
                        </summary>
                        <details-menu className="
                          js-add-reaction-popover
                          anim-scale-in
                          dropdown-menu dropdown-menu-sw
                          mr-n1
                          mt-n1
                        " style={{width: '150px'}} role="menu">
                          <input type="hidden" name="_method" defaultValue="put" /><input type="hidden" name="authenticity_token" defaultValue="bE+4lpERn2KY9SRv99imOx+IyHd4wEfftQ8NQ/2KDwEkhD00rer6jtULkX9fJPuA/wxz0bx7IJWPuYTElirkwQ==" />
                          <p className="color-text-secondary mx-2 my-1">
                            <span className="js-reaction-description">Pick your reaction</span>
                          </p>
                          <div role="none" className="dropdown-divider" />
                          <div className="clearfix d-flex flex-wrap m-1 ml-2 mt-0">
                            <input type="hidden" name="input[subjectId]" defaultValue="MDEyOklzc3VlQ29tbWVudDQzNTEzNTI5Mw==" />
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="THUMBS_UP react">
                              <g-emoji alias={+1} fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f44d.png" className="emoji">👍</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="THUMBS_DOWN react">
                              <g-emoji alias={-1} fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f44e.png" className="emoji">👎</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="LAUGH react">
                              <g-emoji alias="smile" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f604.png" className="emoji">😄</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="HOORAY react">
                              <g-emoji alias="tada" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f389.png" className="emoji">🎉</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="CONFUSED react">
                              <g-emoji alias="thinking_face" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f615.png" className="emoji">😕</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="HEART react">
                              <g-emoji alias="heart" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/2764.png" className="emoji">❤️</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="ROCKET react">
                              <g-emoji alias="rocket" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f680.png" className="emoji">🚀</g-emoji>
                            </button>
                            <button type="submit" role="menuitem" className="
                              btn-link
                              col-3
                              flex-content-center flex-items-center
                              no-underline
                              add-reactions-options-item
                              js-reaction-option-item
                            " name="input[content]" value="EYES react">
                              <g-emoji alias="eyes" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f440.png" className="emoji">👀</g-emoji>
                            </button>
                          </div>
                        </details-menu>
                      </details>
                      <details className="
                        details-overlay details-reset
                        position-relative
                        d-inline-block
                      ">
                        <summary className="btn-link timeline-comment-action Link--secondary" role="button">
                          <svg role="img" viewBox="0 0 16 16" version="1.1" height={16} width={16} className="octicon octicon-kebab-horizontal">
                            <path d="M8 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM1.5 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm13 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                          </svg>
                        </summary>
                        <details-menu className="
                          dropdown-menu dropdown-menu-sw
                          show-more-popover
                          color-text-primary
                          anim-scale-in
                        " style={{width: '185px'}} src="/facebook/react/issue_comments/435135293/comment_actions_menu?href=%23issuecomment-435135293&gid=MDEyOklzc3VlQ29tbWVudDQzNTEzNTI5Mw%3D%3D" preload role="menu">
                          <include-fragment>
                            <p className="text-center mt-3">
                              <svg style={{boxSizing: 'content-box', color: 'var(--color-icon-primary)'}} viewBox="0 0 16 16" fill="none" width={32} height={32} className="anim-rotate">
                                <circle cx={8} cy={8} r={7} stroke="currentColor" strokeOpacity="0.25" strokeWidth={2} vectorEffect="non-scaling-stroke" />
                                <path d="M15 8a7.002 7.002 0 00-7-7" stroke="currentColor" strokeWidth={2} strokeLinecap="round" vectorEffect="non-scaling-stroke" />
                              </svg>
                            </p>
                          </include-fragment>
                        </details-menu>
                      </details>
                    </div>
                    <div className="d-none d-sm-flex flex-items-center">
                      <span className="timeline-comment-label">
                        🏘️ &nbsp; Village Idiot
                      </span>
                    </div>
                    <div className="d-none d-sm-flex flex-items-center" />
                    <h3 className="timeline-comment-header-text f5 text-normal">
                      <a className="d-inline-block d-md-none" href="https://fullstackcraft.com"><img className="avatar rounded-1 avatar-user" height={20} width={20} alt="FullStackCraft's avatar" src="https://coffee-app.sfo2.cdn.digitaloceanspaces.com/fullstackcraft/logo.jpg" /></a>
                      <strong className="css-truncate Link--primary">
                        {username}
                      </strong>
                      {' '}commented{' '}
                      on{' '}{date}
                      <span className="js-comment-edit-history">
                        <span className="d-inline-block color-text-tertiary">•</span>
                        <details className="
                          details-overlay details-reset
                          d-inline-block
                          dropdown
                          hx_dropdown-fullscreen
                        ">
                          <summary className="
                            btn-link
                            no-underline
                            color-text-secondary
                            js-notice
                          " role="button">
                            <div className="position-relative">
                              <span> edited </span>
                            </div>
                          </summary>
                          <details-menu className="
                            dropdown-menu dropdown-menu-s
                            width-auto
                            py-0
                            js-comment-edit-history-menu
                          " style={{maxWidth: '352px', zIndex: 99}} src="/_render_node/MDEyOklzc3VlQ29tbWVudDQzNTEzNTI5Mw==/comments/comment_edit_history_log" preload role="menu">
                            <include-fragment className="my-3" style={{minWidth: '100px'}}>
                              <svg style={{boxSizing: 'content-box', color: 'var(--color-icon-primary)'}} viewBox="0 0 16 16" fill="none" width={32} height={32} className="mx-auto d-block anim-rotate">
                                <circle cx={8} cy={8} r={7} stroke="currentColor" strokeOpacity="0.25" strokeWidth={2} vectorEffect="non-scaling-stroke" />
                                <path d="M15 8a7.002 7.002 0 00-7-7" stroke="currentColor" strokeWidth={2} strokeLinecap="round" vectorEffect="non-scaling-stroke" />
                              </svg>
                            </include-fragment>
                          </details-menu>
                        </details>
                      </span>
                    </h3>
                  </div>
                  <div className="edit-comment-hide">
                    <task-lists disabled sortable>
                      <table className="d-block">
                        <tbody className="d-block">
                          <tr className="d-block">
                            <td className="
                              d-block
                              comment-body
                              markdown-body
                              js-comment-body
                            ">
                              <p>{text}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </task-lists>
                    <div className="
                      comment-reactions
                      flex-items-center
                      border-top
                      has-reactions
                      js-reactions-container
                    ">
                      <input type="hidden" name="_method" defaultValue="put" /><input type="hidden" name="authenticity_token" defaultValue="Up77OBKqKZfAMosLHUj0fPRQauB5R6m0kiMsEBekL2saVX6aLlFMe43MPhu1tKnHFNTRRr38zv6olaWXfATEqw==" />
                      <input type="hidden" name="input[subjectId]" defaultValue="MDEyOklzc3VlQ29tbWVudDQzNTEzNTI5Mw==" />
                      <div className="comment-reactions-options">
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="THUMBS_UP react">
                          <g-emoji alias="thumbs up" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f44d.png" className="emoji mr-1">👍</g-emoji>
                          {' '}236
                        </button>
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="LAUGH react">
                          <g-emoji alias="laugh" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f604.png" className="emoji mr-1">😄</g-emoji>
                          23
                        </button>
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="HOORAY react">
                          <g-emoji alias="hooray" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f389.png" className="emoji mr-1">🎉</g-emoji>
                          21
                        </button>
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="CONFUSED react">
                          <g-emoji alias="confused" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f615.png" className="emoji mr-1">😕</g-emoji>
                          5
                        </button>
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="HEART react">
                          <g-emoji alias="heart" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/2764.png" className="emoji mr-1">❤️</g-emoji>
                          24
                        </button>
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="ROCKET react">
                          <g-emoji alias="rocket" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f680.png" className="emoji mr-1">🚀</g-emoji>
                          23
                        </button>
                        <button className="btn-link reaction-summary-item" name="input[content]" type="submit" value="EYES react">
                          <g-emoji alias="eyes" fallback-src="https://github.githubassets.com/images/icons/emoji/unicode/1f440.png" className="emoji mr-1">👀</g-emoji>
                          27
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
