import * as React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/utils/SEO"
import { Create } from "../components/pages/create/Create"

const CreatePage = () => (
  <Layout>
    <SEO title="Create a Sticker" />
    <Create/>
  </Layout>
)

export default CreatePage
