import * as React from 'react';
import { GenerateMockUpButton } from './GenerateMockUpButton';
import { StickerPreview } from './StickerPreview';

export interface IPreviewProps {
}

export function Preview (props: IPreviewProps) {
  return (
    <div className="d-flex flex-column align-items-center">
      <h2>Preview</h2>
      <StickerPreview/>
      <GenerateMockUpButton/>
    </div>
  );
}
